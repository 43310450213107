import { useEffect, useState } from "preact/compat";
import init, { get_key, decrypt } from "../../../wasm/pkg/index";

const Redirect = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    async function run() {
      const url = new URL(window.location);
      await init();
      const urlSegments = window.decodeURI(url.pathname).split("/");
      const slug = urlSegments[urlSegments.length - 1];
      const key = get_key(slug);
      const {
        value
      } = await fetch(`https://apis.short.wasc.me/${key}`).then(response => response.json());

      if (!value) {
        return setMessage(`No link found for s.wasc.me/${slug}.`);
      }

      const {
        payload,
        salt
      } = JSON.parse(value);
      const link = decrypt(slug, payload, salt); // TODO Is this a good idea?

      const redirectTarget = link.match(/https?/) ? link : `https://${link}`;
      setMessage(`Redirecting to: ${redirectTarget}...`);
      return window.location.replace(redirectTarget);
    }

    run();
  }, [init]);
  return h("span", {
    class: "text-lg py-4 px-2"
  }, message);
};

export default Redirect;