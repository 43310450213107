import { lazy } from "preact/compat";
import { WaitComponent } from "../Aysnc/Async";
const Create = lazy(() => import("../Create/Create"));

const Main = () => {
  return h("div", {
    class: "w-screen h-screen flex flex-col"
  }, h("div", {
    class: "flex justify-end pr-4 pt-4"
  }), h("div", {
    className: "max-w-6xl mx-auto px-4 sm:px-6 h-full"
  }, h("div", {
    className: "pt-32 pb-12 md:pt-40 md:pb-20"
  }, h("div", {
    className: "text-center pb-12 md:pb-16"
  }, h("h1", {
    className: "text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4",
    "data-aos": "zoom-y-out"
  }, "Your links", " ", h("span", {
    className: "bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-600"
  }, "protected")), h("div", {
    className: "max-w-3xl mx-auto"
  }, h("p", {
    className: "text-lg text-gray-600 mb-8"
  }, "Fully Anonymous, Encrypted, 0-Trust, No Tracking"), h("div", {
    className: "max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
  }, h("div", {
    className: "ml-0 mt-2 sm:mt-0 sm:ml-4 w-full md:w-8/12"
  }, h(WaitComponent, {
    Component: Create
  }))))))), h("div", {
    class: "p-3 w-full inline-flex justify-start bg-gray-100"
  }, h("ul", {
    class: "w-full inline-flex justify-between"
  }, h("li", null, h("a", {
    href: "https://blog.wasc.io",
    target: "_blank",
    rel: "noreferrer",
    class: "text-blue-400 underline ml-2"
  }, "Wasc Blog"), h("a", {
    href: "https://wasc.me",
    target: "_blank",
    rel: "noreferrer",
    class: "text-blue-400 underline ml-2"
  }, "Wasc Mail")), h("li", null, h("a", {
    href: "https://wasc.io/imprint-privacy",
    target: "_blank",
    rel: "noreferrer",
    class: "text-blue-400 underline"
  }, "Impressum & Datenschutz"), h("a", {
    href: "https://wasc.io/terms",
    target: "_blank",
    rel: "noreferrer",
    class: "text-blue-400 underline ml-2"
  }, "AGBs")))));
};

export default Main;